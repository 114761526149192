<template>
  <v-autocomplete
    v-model="selected"
    :label="label"
    :items="items"
    :return-object="returnObject"
    :clearable="clearable"
    item-value="id"
    item-text="full_name"
  >
    <!-- Selected item -->
    <template v-slot:selection="{ item }">
      <v-list
        v-if="$vuetify.breakpoint.smAndUp"
        class="p-0"
        style="max-width: 450px;"
      >
        <v-list-item>
          <v-list-item-avatar>
            <v-avatar color="primary" :size="32">
              <img v-if="item.image_url" :src="item.image_url" />
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content class="mt-1 mb-1">
            <v-list-item-title v-text="item.name"></v-list-item-title>
            <v-list-item-subtitle
              class="text--secondary"
              v-text="item.business.display_name"
            ></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list-item-title
        v-else
        v-html="item.name"
        class="p-0"
        style="max-width: 220px;"
      ></v-list-item-title>
    </template>

    <v-list slot="no-data">
      <v-list-item>
        <v-list-item-title>No result</v-list-item-title>
      </v-list-item>
    </v-list>

    <!--List item-->
    <template slot="item" slot-scope="prop">
      <v-list-item-avatar>
        <v-avatar color="primary" :size="32">
          <img v-if="prop.item.image_url" :src="prop.item.image_url" />
        </v-avatar>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title v-html="prop.item.name"></v-list-item-title>
        <v-list-item-subtitle
          v-html="prop.item.business.display_name"
        ></v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>
<script>
export default {
  props: {
    value: {
      required: false
    },
    label: {
      default: "Campaign"
    },
    items: {
      required: false,
      default: null
    },
    returnObject: {
      required: false,
      type: Boolean,
      default: true
    },
    clearable: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  }
};
</script>
